import React from 'react';
import '../styles/loading.css';

const Loading = () => {
  return (
    <>
    <div class="loading-div">
  <div class="loader"></div>
  <div class="loading-text"></div>
</div>
    
    </>
    
    
  );
};

export default Loading;