import Plant1 from "../images/Plant1.jpg";
import Plant2 from "../images/Plant2.jpg";
import Plant3 from "../images/Plant3.jpg";
import Plant4 from "../images/Plant4.jpg";
import Plant5 from "../images/Plant5.jpg";
import Plant6 from "../images/Plant6.jpg";
import Plant7 from "../images/Plant7.jpeg";
import Plant8 from "../images/Plant8.jpg";
import Plant9 from "../images/Plant9.jpg";
import Plant10 from "../images/Plant10.jpeg";
import Plant11 from "../images/Plant11.jpg";
import Plant12 from "../images/Plant12.jpg";
import Plant13 from "../images/Plant13.jpg";
import Plant14 from "../images/Plant14.jpg";
import Plant15 from "../images/Plant15.jpg";
import Plant16 from "../images/Plant16.jpg";
import Plant17 from "../images/Plant17.jpg";
import Plant18 from "../images/Plant18.jpg";
import Plant19 from "../images/Plant19.jpg";
import Plant20 from "../images/Plant20.jpg";
import Plant21 from "../images/Plant21.jpg";
import Plant22 from "../images/Plant22.jpeg";
import Plant23 from "../images/Plant23.jpg";
import Plant24 from "../images/Plant24.jpg";
import Plant25 from "../images/Plant25.jpg";
import Plant26 from "../images/Plant26.jpg";
import Plant27 from "../images/Plant27.jpg";
import Plant28 from "../images/Plant28.jpeg";
import Plant29 from "../images/Plant29.jpg";
import Plant30 from "../images/Plant30.jpg";
import Plant31 from "../images/Plant31.jpeg";
import Plant32 from "../images/Plant32.jpg";
import Plant33 from "../images/Plant33.jpg";
import Plant34 from "../images/Plant34.jpeg";
import Plant35 from "../images/Plant35.jpg";

const plantDetails = [
  {
    name: "White Butterfly (Syngonium Podophyllum)",
    price: "₹ 244",
    description:
      "Syngonium Podophyllum White Butterfly plant is a perfect house plant...",
    sunlight: "Bright indirect light",
    watering: "Moderately, water when topsoil is dry",
    features: "Excellent air purifier",
    usage: "indoor",
    image: Plant1,
  },
  {
    name: "Peace Lily",
    price: "₹ 359",
    description: "The Peace lily is known as the best air purifier plant...",
    sunlight: "Bright indirect light",
    watering: "Water when topsoil is dry, avoid overwatering",
    features: "Air purifying, low maintenance",
    usage: "indoor",
    image: Plant2,
  },
  {
    name: "Chlorophytum Spider Plant",
    price: "₹ 259",
    description:
      "Chlorophytum is an attractive green plant with white stripe variegation...",
    sunlight: "Natural bright light/indirect bright light",
    watering: "Water when topsoil feels dry",
    features: "Air purifying, great for AC rooms",
    usage: "indoor/outdoor",
    image: Plant3,
  },
  {
    name: "Money Plant Marble Prince",
    price: "₹ 199",
    description:
      "Marble prince plants are known to bring good luck and prosperity...",
    sunlight: "Natural indirect/artificial bright light",
    watering: "Water when topsoil feels dry",
    features: "NASA recommended air purifier",
    usage: ["indoor", "outdoor"],
    image: Plant4,
  },
  {
    name: "Snake Plant (Sansevieria)",
    price: "₹ 339",
    description:
      "A popular houseplant with dark green leaves marked with wavy cross stripes...",
    sunlight: "Bright indirect natural light or bright artificial light",
    watering: "Water when topsoil feels dry",
    features: "Air purifying, low maintenance",
    usage: "indoor",
    image: Plant5,
  },
  {
    name: "Aglaonema Lipstick",
    price: "₹ 299",
    description:
      "Aglaonema is one of the most popular indoor colorful foliage plants...",
    sunlight: "Bright indirect light",
    watering: "Water when topsoil is dry",
    features: "Air purifying, colorful foliage",
    usage: "indoor",
    image: Plant6,
  },
  {
    name: "Jade Plant (Portulacaria afra)",
    price: "₹ 169",
    description:
      "The jade plant is an extremely popular succulent houseplant...",
    sunlight: "Morning sunlight for 3-6 hours",
    watering: "Water when topsoil feels dry",
    features: "Low maintenance, brings good fortune",
    usage: "indoor",
    image: Plant7,
  },
  {
    name: "Rubber Tree (Ficus elastica)",
    price: "₹ 259",
    description:
      "A popular ornamental foliage plant to enhance indoor and outdoor spaces...",
    sunlight: "Bright morning sunlight, indirect bright light",
    watering: "Water when topsoil feels dry",
    features: "Air purifying, glossy leaves",
    usage: ["indoor", "outdoor"],
    image: Plant8,
  },
  {
    name: "Krishna Tulsi Plant(Black)",
    price: "₹ 259",
    description:
      "Krishna tulsi is part of routine worship and has a scientific background as the plants possess antimicrobial and antiviral properties and purify the air",
    sunlight: "Keep the plant outdoor in natural bright light.",
    watering: "Water when top soil (1-2 inches) feels dry to touch.",
    features:
      "Krishna Tulsi is used in treating low energy, ulcers, vomiting and diarrhea or as an overall tonic",
    usage: "outdoor",
    image: Plant9,
  },
  {
    name: "Lemon Grass",
    price: "₹ 259",
    description:
      "Lemon Grass is a fragrant herb which is increasingly being used in teas, beverages, herbal medicines.",
    sunlight: "Full sun",
    watering: "Medium",
    features:
      "Lemon Grass is a fragrant herb which is increasingly being used in teas, beverages, herbal medicines",
    usage: "outdoor",
    image: Plant10,
  },
  {
    name: "Curry Leaves, Kadi Patta",
    price: "₹ 359",
    description:
      "Murraya koenigii is a most attractive, small evergreen tree with wonderfully sweet, curry-pungent foliage",
    sunlight: "Keep the plant outdoor with bright natural light.",
    watering:
      "Water the plant when the topsoil (1-2 inch) in pot feels dry to touch.",
    features:
      "Curry leaves are said to have alkaloids that help heal wounds that are not too deep",
    usage: "outdoor",
    image: Plant11,
  },
  {
    name: "Rama Tulsi Plant",
    price: "₹ 199",
    description:
      "Tulsi or Tulasi (Ocimum tenuiflorum) or Holy basil is a sacred plant in Hindu belief.",
    sunlight: "Indirect sunlight is best for basil plants.",
    watering: "Medium",
    features: "Tulsi may cure fever, cold,cough and sore throat",
    usage: "outdoor",
    image: Plant12,
  },
  {
    name: "Ajwain Leaves",
    price: "₹ 259",
    description:
      "Planting an herbal plant in your garden is a wonderful way to enjoy the sights, smells, and tastes of plants",
    sunlight: "Keep the plant in bright sunlight.",
    watering:
      "Water the plant when the topsoil (1-2 inch) in pot feels dry to touch.",
    features: "It contains essential oils and does help in health problems",
    usage: "outdoor",
    image: Plant13,
  },

  {
    name: "Mentha Arvensis, Japanese Mint",
    price: "₹ 199",
    description:
      "Wild mint is a herbaceous perennial plant generally growing to 10to60 cm (3.9to23.6 in)",
    sunlight: "Full sun to part shade",
    watering: "Dry to medium.",
    features:
      "Interesting plant for the herb garden, border, naturalized area or meadow.",
    usage: "outdoor",
    image: Plant14,
  },
  {
    name: "Black Turmeric Plant, Black Haldi",
    price: "₹ 499",
    description:
      "black turmeric or black zedoary is a perennial herb with bluish-black rhizome,native to North-East and Central India.",
    sunlight: "Turmeric prefers warm direct or indirect sun.",
    watering:
      "Keep the soil moist throughout the growing season from spring to fall.",
    features:
      "The rhizome of the plant is aromatic, contains essential oil and used for a variety of purposes.",
    usage: "outdoor",
    image: Plant15,
  },
  {
    name: "Bhuiamla, Phyllanthus niruri",
    price: "₹ 209",
    description:
      "It is an annual herb and as a whole used in various treatments.",
    sunlight: "Full sunlight",
    watering: "dry season if monsoon rains are scanty",
    features:
      "It is used in local herbal medicine there for poor appetite, constipation, typhoid fever, flu and colds",
    usage: "outdoor",
    image: Plant16,
  },
  {
    name: "Wild Asparagus, Shatavari",
    price: "₹ 259",
    description:
      "Shatavari, sometimes known as wild asparagus, is primarily a reproductive tonic (both male and female).",
    sunlight: "Partial shade",
    watering: "Medium",
    features:
      "Asparagus good for heart health, in regulating blood sugar, Anti-aging benefits.",
    usage: "outdoor",
    image: Plant17,
  },
  {
    name: "Jasminum sambac, Mogra",
    price: "₹ 299",
    description:
      "Flowers make intimate connections they increase our connectivity with family and friends.",
    sunlight: "3-6 hours of sunlight a day is ideal for flowering",
    watering: "The soil should be well drained",
    features:
      "Plant is known for its heavenly fragrance especially in the evenings as the flowers open after",
    usage: "outdoor",
    image: Plant18,
  },
  {
    name: "Parijat Tree",
    price: "₹ 359",
    description:
      "A tree that seemed to possess a beauty so rare that it made you question whether it was real or your imaginatiom",
    sunlight: "More than 6 hours of sunlight or 3-6 hours",
    watering: "The soil should be well drained",
    features:
      "The Parijat tree&#39;s flowers bloom only at night and by morning, they have fallen to ground",
    usage: "outdoor",
    image: Plant19,
  },
  {
    name: "Rose, Scented Rose",
    price: "₹ 399",
    description:
      "Damascus roses are known for its distinct fragrance. Enjoy the real and oldest scent of rose.",
    sunlight: "Full to partial sunlight",
    watering: "Moderately",
    features:
      "They have been also used for commercial perfumery and commercial cut flower crops",
    usage: "outdoor",
    image: Plant20,
  },
  {
    name: "Raat Rani, Night Blooming Jasmine",
    price: "₹ 299",
    description:
      "The Raat Ki Rani belongs to the Solanaceae family. Raat ki rani is an evergreen woody shrub growing tall",
    sunlight: "3-6 hrs of sunlight is ideal for flowering",
    watering: "The soil should be well drained and fertile,",
    features:
      "Raat ki rani fragrance is heavenly especially in the night as the flowers open after sunset and the plaant is in its full beauty",
    usage: "outdoor",
    image: Plant21,
  },
  {
    name: "Shevanti",
    price: "₹ 259",
    description:
      "The most popular garden varieties are offered as cushion mums and produce dense, compact low mounds smothered with flowers.",
    sunlight: "Shevanti plant needs full sun.",
    watering: "Water plant once in a week.",
    features:
      "The plant possesses anti-inflammatory, anti-gout and anti-thrombic activity",
    usage: "outdoor",
    image: Plant22,
  },
  {
    name: "Marigold (Orange)",
    price: "₹ 299",
    description:
      "Marigold are among the very popular flowers commonly found in India and other countries.",
    sunlight: "Marigolds need lots of sunshine",
    watering: "Water marigolds the first 10 to 12 days after transplanting bedding plants",
    features:
      "In India marigold is one of the most commonly grown flowers and used extensively on religious functions",
    usage: "outdoor",
    image: Plant23,
  },
  {
    name: "Champa (White)",
    price: "₹ 459",
    description:
      "Growing Plumeria is an easy way to add a tropical flair to your garden.",
    sunlight: "Moderate",
    watering: "Water when top soil (1-2 inches) feels dry to touch.",
    features:
      "This champa plant is cultivated as an ornamental plant, as a dense screening hedge, and for its fragment",
    usage: "outdoor",
    image: Plant24,
  },
  {
    name: "Rajnigandha",
    price: "₹ 199",
    description:
      "The tuberose is a night-blooming plant native to Mexico, as is every other known species of Polianthes",
    sunlight: "Full Sun",
    watering: "Medium",
    features:
      "The plant is used for ornamental purpose",
    usage: "outdoor",
    image: Plant25,
  },

  {
    name: "Fragrant Panama rose",
    price: "₹ 279",
    description:
      "Panama roses are known for its distinct fragrance.",
    sunlight: "3-6 hrs of sunlight ideal for flowering",
    watering: "Apply 4 cup (Approx. 200 ml) water when the top soil (1-2 inch) in pot",
    features:
      "The plant is used for ornamental purpose and its fragnance",
    usage: "outdoor",
    image: Plant26,
  },
  {
    name: "Pincushion Cactus",
    price: "₹ 299",
    description:
      "Pincushion cactus is a large group of small, free-flowering cacti that are easy to care",
    sunlight: "Full Sun",
    watering: "Medium",
    features:
      "The plant is used for ornamental purpose",
    usage: "outdoor",
    image: Plant27,
  },

  {
    name: "Bunny Ear Cactus, Opuntia microdasys",
    price: "₹ 299",
    description:
      "shion cactus is a large group of small, free-flowering cacti that are easy to careDensely spined, and in many types the spines are so elongated and soft that the plant appears hairy",
    sunlight: "Full Sun",
    watering: "Medium",
    features:
      "The plant is used for ornamental purpose",
    usage: "outdoor",
    image: Plant28,
  },
  {
    name: "Echinopsis chamaecereus – Cactus",
    price: "₹ 299",
    description:
      "Echinopsis chamaecereus is a type of cactus which is drought tolerant and grows somewhat cylindrical",
    sunlight: "Full sun, partial sun, at least 4 to 6 hours of sunlight per day.",
    watering: "Low for the rainy season, Moderate for winter,",
    features:
      "Its color and the appearance is appreciated by the various gardens",
    usage: "outdoor",
    image: Plant29,
  },
  {
    name: "Golden Pipe Cactus",
    price: "₹ 259",
    description:
      "Golden Pipe Cactus is a type of cactus which is drought tolerant and grows somewhat cylindrical",
    sunlight: "Full sun, partial sun, at least 4 to 6 hours of sunlight per day.",
    watering: "Low for the rainy season, Moderate for winter",
    features:
      "Its color and the appearance is appreciated by the various gardens",
    usage: "outdoor",
    image: Plant30,
  },
  {
    name: "Moon Cactus (Grafted, Yellow)",
    price: "₹ 399",
    description:
      "The popular moon cactus plant is a small grafted cactus.",
    sunlight: "Bright indirect sunlight",
    watering: "Water moon cactus thoroughly, then let the plant dry out between waterings",
    features:
      "Plants are used for an ornamental purpose",
    usage: "outdoor",
    image: Plant31,
  },
  {
    name: "Graptoveria opalina - Succulent Plant",
    price: "₹ 279",
    description:
      "An attractive and durable succulent plant that produces clustes of tight rosettes",
    sunlight: "Full sun to part shade",
    watering: "Dry to medium",
    features:
      "Cactus or succulent garden where they can be effectively mixed with aloes, jade plants and agave",
    usage: "outdoor",
    image: Plant32,
  },
  {
    name: "Crassula tetragona - Succulent Plant",
    price: "₹ 279",
    description:
      "This miniature pine tree-looking succulents can grow up to 3-4 feet tall.",
    sunlight: "At least 3-6 hours of preferably morning sunlight a day",
    watering: "Apply 1 cup (Approx. 50 ml ) water when top soil",
    features:
      "The plant is used for ornamental purpose like borders, containers, edging",
    usage: "outdoor",
    image: Plant33,
  },
  {
    name: "Aloevera",
    price: "₹ 259",
    description:
      "Aloe vera, Ghrita-kumari is the famous medicinal plant with ornamental and cultural beliefs.",
    sunlight: "Keep the plant in morning sunlight for 3 to 6 hours",
    watering: "Water when topsoil (3-4 inches) feels dry to touch.",
    features:
      "Aloe vera is used in traditional medicine",
    usage: "outdoor",
    image: Plant34,
  },
  {
    name: "Euphorbia (Red)",
    price: "₹ 359",
    description:
      "Crown of Thorns plant is one of the rare succulents capable of blooming almost year round",
    sunlight: "Bright light to full sun.",
    watering: "Water when topsoil (3-4 inches) feels dry to touch.",
    features:
      "Whether you want a giant thornless cactus specimen 6 feet tall or a creeping, sweetly flowering",
    usage: "outdoor",
    image: Plant35,
  },
];

export default plantDetails;
